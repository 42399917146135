import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'

import HomePage from "./HomePage"
import Reseller from "./Reseller"
import Tenant from "./Tenant"
import EditUser from "./EditUser"
import Users from "./Users"
import EditTenant from "./EditTenant"
import Licences from "./Licences"
import BuyLicence from "./BuyLicence"
import AddNewTenant from "./AddNewTenant"
import TenantUsers from "./TenantUsers"
import AddNewUser from "./AddNewUser"
import TenantLicences from "./TenantLicences"
import DefaultSettings from "./DefaultSettings"
import Footer from "./Footer"
import User from "./User"
import Error from "./Error"
import Support from "./Support"
import SuperTenant from './SuperTenant'

import './App.css'

//IMPORTANT!!
//Signin via the https://signin-va.cloudcti.nl/signin/onelogin ONLY works if the app runs in a *.cloudcti.nl domain
//It will NOT work in a localhost environment, and it will NOT work in an *.azurewebsites.net environment
//For these latter purposes, the debug lines in the App() can be used to force a user

function App() {

  const token = Cookies.get("resellerportal")
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      sessionStorage.setItem("username", decodedToken.UserName);
      sessionStorage.setItem("token", token);
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }

  //sessionStorage.setItem("username","Arash Reseller")
  //sessionStorage.setItem("token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTaWduaW5NZXRob2QiOiJDbG91ZENUSSIsIlNpZ25pblVzZXIiOiJwcm92aWRlcmFyYXNoQGNsb3VkY3RpLm5sIiwiU2lnbmluUGFzc3dvcmQiOiJtejFSbUpmMWlyZUozY1Z4djBPQzhjK0VOR0FvYkdlSFJaU2Y0RXpzNjM4c0d2ek94R0hucTZLeitIZ2QyaHJwWFFNTmtjb2lLQ256QkJVSXdJaFAwU1d6Mm1VVU5iSmtsdEdkbEFHLzAxcVcrZlczZmNCQUlqT0pZeE9TQ2o4TyIsIlNpZ25pbkFwcGxpY2F0aW9uIjoiUmVzZWxsZXJQb3J0YWwiLCJUZW5hbnRHdWlkIjoiVGVuYW50XzgwNGM2NDQ4LWIyZGItNDQ5MC05MzI5LTVjMzBiMDllYmQ2ZCIsIlRlbmFudE5hbWUiOiJQcm92aWRlciBBcmFzaCIsIlVzZXJHdWlkIjoiVGVuYW50VXNlcl85ODY2ZWM1Ny1jYWE2LTRiMGMtOTk3MC1kNTJhZWY4OGQwNmYiLCJVc2VyTmFtZSI6IlByb3ZpZGVyIEFyYXNoIHBvcnRhbCBhY2NvdW50IiwiVXNlcklzQWRtaW4iOiIwIiwiVXNlcklzVGVuYW50QWRtaW4iOiIwIiwiRW1haWwiOiJwcm92aWRlcmFyYXNoQGNsb3VkY3RpLm5sIiwiUmVzZWxsZXJHdWlkIjoiUmVzZWxsZXJfNzQ0ODk2RTMtRjY4MS00NDk5LTgyMUEtMkE5ODFBOThEMDgzIiwiSXNTdXBlclRlbmFudCI6IjAiLCJSb2xlcyI6IlVzZXIsUmVzZWxsZXIiLCJleHAiOjE3MzY5Mjg5MzgsImlzcyI6InNpZ25pbi12YSIsImF1ZCI6IkNsb3VkQ1RJMjAyMiJ9.am8nmnjyNaIAdZJvPnl2g7asfOMeinta3NhaxPYwcps")
  
  if (sessionStorage.getItem("token"))
  {
      return (
        <Router>
          <Routes>
            <Route path="/edittenant" element={<EditTenant/>} />
            <Route path="/tenant" element={<Tenant/>}/>
            <Route path="/reseller" element={<Reseller/>} />
       x    <Route path="/" element={<HomePage/>} />
            <Route path="/edituser" element={<EditUser/>} />
            <Route path="/users" element={<Users/>} />
            <Route path="/licences" element={<Licences/>} />
            <Route path="/buylicence" element={<BuyLicence/>} />
            <Route path="/addnewtenant" element={<AddNewTenant/>} />
            <Route path="/tenantusers" element={<TenantUsers/>} />
            <Route path="/addnewuser" element={<AddNewUser/>} />
            <Route path="/tenantlicences" element={<TenantLicences/>} />
            <Route path="/defaultsettings" element={<DefaultSettings/>} />
            <Route path="/footer" element={<Footer/>}/>
            <Route path="/user" element={<User/>}/>
            <Route path="/error" element={<Error/>}/>
            <Route path="/support" element={<Support/>}/>
            <Route path="/supertenant" element={<SuperTenant/>}/> 
          </Routes>
        </Router>
      );
    }

    else
    {   
      //const redirecturl = window.location.href.replaceAll(":","%3A").replaceAll("/","%2F").replaceAll("&","%26")   
      const redirecturl = window.location.protocol + '//' + window.location.host  
      window.location.href = "https://signin-va.cloudcti.nl/signin/onelogin?clientid=resellerportal&redirecturl=" + redirecturl
    }  
  }

export default App;

