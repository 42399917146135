import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { TenantFrontendApi_GetTenantList } from "./TenantFrontendApi";
import { Translate } from "./Translator"
import Header from "./Header";
import BlueBar from "./BlueBar"
import Footer from "./Footer"
import MainMenu from "./MainMenu";

import AccountLogo from "./Assets/AccountLogo.svg"
import HomeLogo from "./Assets/HomeLogo.png"
import SearchButton from "./Assets/SearchButton.png"
import DocumentLogo from "./Assets/DocumentLogo.jpg"
import PlusIcon from "./Assets/PlusIcon.jpg"
import HamburgerIcon from "./Assets/HamburgerIcon.png"

import './Reseller.css'

function Reseller(){

    const [mRepaint, SetRepaint] = useState()
    const navigate = useNavigate();
    const [mSearchValue,SetSearchValue]=useState("")
    const [mAllTenantList,SetAllTenantList] = useState([])
    const [isSuperTenantColumnVisible, setIsSuperTenantColumnVisible] = useState(false);
    const [mNavigationBarExpanded,SetNavigationBarExpanded] = useState(false)
    
    useEffect(() => {

        async function GetTenantList() {

            try
            {
                console.log("Getting tenants")
                const TenantListResponse = await TenantFrontendApi_GetTenantList(sessionStorage.getItem("token"))
                SetAllTenantList(TenantListResponse.data)
                console.log(TenantListResponse.data)
                sessionStorage.setItem("resellerguid",TenantListResponse.data[0].ResellerGuid)
            }
            
            catch (ex)
            {
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }

        GetTenantList()
        

    }, []);

    function CreateNewTenantButton(){
        return(
            navigate('../addnewtenant')
        )
    }

    function TenantUsers(aTenantGuid){
        navigate('../tenantusers?tenantguid=' + aTenantGuid)
    }

    function TenantLicences(aTenantGuid){
        navigate('../tenantlicences?tenantguid=' + aTenantGuid)
    }

    function languageChanged(){
        SetRepaint(!mRepaint)
    }

    let filteredTenantList = []
    const lowerSearchValue = mSearchValue.toLowerCase()

    if (lowerSearchValue === "") 
    {
        filteredTenantList = mAllTenantList
    } 
    else 
    {
        filteredTenantList = mAllTenantList.filter(
            tenant => ( tenant.Company.toLowerCase().includes(lowerSearchValue) ||
                        tenant.ApiSettings.toLowerCase().includes(lowerSearchValue) ||
                        tenant.LicenceSummary.toLowerCase().includes(lowerSearchValue) 
                    )
        )
    }

    

    // Toggle visibility of the "Super tenant" column
    const toggleSuperTenantColumnVisibility = () => {
        setIsSuperTenantColumnVisible(prevState => !prevState);
        SetNavigationBarExpanded(prevState => !prevState);
        console.log(mNavigationBarExpanded)
      };
      

    // Handle keypress for Enter key
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && filteredTenantList.length === 1) {
        const tenant = filteredTenantList[0];
        navigate(`../tenant?tenantguid=${tenant.TenantGuid}`);
        }
    };

    return(
        <span>

            <Header onLanguageChanged={() => languageChanged()}/>
            
            <MainMenu MainMenuNumber={1} />
            
            <table className="tenanttable" width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>
                                    
                    <tr className="pagetop3">
                        <td colSpan={4} height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px" >
                                <tbody>
                                    <tr height="60">
                                        <td width="80" align="left">
                                            <img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={() => navigate('/')}/>
                                            <span width="40" align="left" className="bold">{Translate("Tenants")}</span>
                                        </td>                                            
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr className="pagetop4">
                        <td className="freespace"/>
                        <td colSpan={4} height="75" valign="left" className="activetenants">
                            {Translate("Active tenants")}
                        </td>
                    </tr>

                    <tr className="pagetop4">
                        <td className="freespace"/>
                        <td colSpan={4} className="horizontalLine" >
                            <BlueBar/>
                        </td>
                    </tr>

                    <tr height="50"/>
                </tbody>
            </table>

            <table className="width1200" align="center" >
                <tbody>
                  <tr>
                      <td></td>
                      <td colSpan={2} height="50" valign="left">
                          <input type="text" placeholder={Translate("Search")} className="searchtenants" onChange={e=>SetSearchValue(e.target.value)} onKeyDown={handleKeyPress}/>
                          <img alt="Search" className="searchimage pointer" src={SearchButton} onClick={()=>SetRepaint(!mRepaint)} />    
                      </td>
                      <td className="distanceresize" align="center"></td>
                      <td colSpan={2} align="right">
                          <table className="blackbackground borderradius">
                              <tbody>
                                  <tr className="pointer" onClick={() => CreateNewTenantButton()}>
                                      <td width="8"/>
                                      <td><img className="width50" src={PlusIcon} alt="plus icon"/></td>
                                      <td className="bold white">{Translate("Add tenant")}</td>
                                      <td width="20"></td>
                                  </tr>
                              </tbody>
                          </table>
                      </td>
                  </tr>
                  <tr height="10"/>
                </tbody>
            </table>
            
            {/*Tenants table*/}
            <table className="width1200" align="center">
              <tbody>
                <tr className="tenanttableheader">
                  {/* Toggleable Super Tenant column */}
                  {/*<td
                    className={`bold pointer super-tenant-column ${isSuperTenantColumnVisible ? '' : 'collapsed'}`}
                    onClick={toggleSuperTenantColumnVisibility}
                    style={{ cursor: 'pointer' }}
                    width="10 "
                  >
                      <img
                        src={HamburgerIcon}
                        alt="Show Super Tenant"
                        className=""
                        width={30}
                      />
                  </td>*/}

                  {/* Other columns */}
                  <td colSpan={2} className="bold bordertop borderleft bluebackground white">
                    {Translate("Name")}
                  </td>
                  <td className="bold white bordertop borderleft bluebackground widthusertenantstable white">
                    # {Translate("Users")}
                  </td>
                  <td colSpan={2} width="550" className="bold white bordertop borderleft bluebackground white borderright">
                    {Translate("# Bought licenses")}
                  </td>
                </tr>

                {/* Tenant list rows */}
                {filteredTenantList && filteredTenantList.length > 0 ? (
                  filteredTenantList
                    .sort((a, b) => a.Company.localeCompare(b.Company))
                    .map((tenant, index) => (
                      <tr className="contentenatable" key={index}>
                        {/*<td
                          className={`rightpadding bold super-tenant-content blue ${isSuperTenantColumnVisible ? '' : 'hidden'}`}
                          style={{ width: isSuperTenantColumnVisible ? '100px' : '0' }}
                        >
                          {tenant.IsSuperTenant ? Translate("Reseller") : ""}
                        </td>*/}

                        {/* Tenant Name */}
                        <td
                          colSpan={2}
                          className="tenantsname borderbottomright underline pointer bold leftpadding10"
                          onClick={() => navigate('../tenant?tenantguid=' + tenant.TenantGuid)}
                        >
                          {tenant.Company}
                        </td>

                        {/* Number of Users */}
                        <td className="borderbottomright">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <img alt="Account" width="40" src={AccountLogo} />
                                </td>
                                <td
                                  className="viewinfo pointer bold"
                                  onClick={() => TenantUsers(tenant.TenantGuid)}
                                >
                                  {tenant.UserCount}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>

                        {/* Bought Licenses */}
                        <td className="borderbottomright" colSpan={2}>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <img alt="Document" width="40" src={DocumentLogo} />
                                </td>
                                <td
                                  className="viewinfo pointer"
                                  onClick={() => TenantLicences(tenant.TenantGuid)}
                                >
                                  {tenant.LicenceSummary
                                    ? tenant.LicenceSummary
                                    : "No license available"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td
                      className="borderleft borderbottom borderright bold"
                      align="center"
                      colSpan={6}
                    >
                      {Translate("No tenant found")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <table>
                <tbody>
                    <tr height="20"/>
                </tbody>
            </table>

            <Footer/>
            
        </span>    
    )
}

export default Reseller